@font-face {
    font-family: "SCEV";
    src: url("AAAAACSCEV.woff2") format("woff2"),
        url("AAAAACSCEV.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: SCEV, Palatino, "Palatino Linotype";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.initial {
    color: #a54546;
    float: left;
    font-size: 61px;
    line-height: 40px;
    padding-top: 11px;
    padding-right: 4px;
}

.initial-small {
    color: #a54546;
    float: left;
    font-size: 55px;
    line-height: 40px;
    padding-top: 7.5px;
    padding-right: 4px;
}

p {
    margin: 0;
}

.hover:hover {
    opacity: 0.6;
}

.date-tab:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.variation-switch:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.yt-container {
    display: flex;
    justify-content: center;
}

iframe {
    aspect-ratio: 16 / 9;
    width: 100% !important;
}
